import { j as t } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as p } from "react";
import { I as a } from "./index-CAn76F0e.js";
import "./Accordion-CooUcG93.js";
import "./Avatar-D2CKq5wD.js";
import "./Badge-C0bj4DFU.js";
import "./Button-DhTfdjdw.js";
import "./DropdownMenu-BFNfDOKb.js";
import "./Icon-Buzet9rP.js";
import { I as i } from "./Input-vl7Xx2ol.js";
import "./Label-BKL8LTmX.js";
import "./Text-DOFuHqUo.js";
import "./Tooltip-ae2E5kx6.js";
import "./Stack-9Jnn74Nm.js";
import "./Switch-D9CJ1TL2.js";
import "./Toast-D9KoZizy.js";
import "./Toggle-DH_2vm0p.js";
const e = p(
  (m, o) => /* @__PURE__ */ t.jsx(a, { ...m, inputRef: o, mask: "999-999-9999", maskChar: "_", children: (r) => /* @__PURE__ */ t.jsx(
    i,
    {
      wrapClassName: r.wrapClassName,
      ...r,
      disabled: m.disabled,
      type: "tel",
      placeholder: "000-000-0000"
    }
  ) })
);
e.displayName = "PhoneNumberInput";
export {
  e as default
};
